import React from 'react';

import { CheckboxIcon } from '../icons/CheckboxIcon';

interface CalendarKeyProps {
  isCheckbox?: boolean;
}

export const CalendarKey = ({ isCheckbox }: CalendarKeyProps) => (
  <div className="key">
    {isCheckbox ? (
      <>
        <div className="key-element-private-booking">
          <CheckboxIcon />
          <p>Your engineer is available</p>
        </div>
        <div className="key-element-private-booking">
          <div className="key-element-private-booking--checkbox-container">
            <CheckboxIcon color="green" />
            <div className="marketplace-calendar-slot--rated-good slot-calendar-key" />
          </div>
          <p>Your engineer is nearby</p>
        </div>
        <div className="key-element-private-booking">
          <div className="key-element-private-booking--checkbox-container">
            <CheckboxIcon color="#5028e2" />
            <div className="marketplace-calendar-slot--rated-best slot-calendar-key" />
          </div>
          <p>The earliest or best eco slot</p>
        </div>
      </>
    ) : (
      <>
        <div className="key-element">
          <div className="radio-circle" />
          <p>Your engineer is available</p>
        </div>
        <div className="key-element">
          <div className="radio-circle radio-circle--rated-good" />
          <p>Your engineer is nearby</p>
        </div>
        <div className="key-element">
          <div className="radio-circle radio-circle--rated-best" />
          <p>The earliest or best eco slot</p>
        </div>
      </>
    )}
  </div>
);
