/**
 * TODO: consider using Apollo Client's reactive vars instead
 */
import React, { useContext, useState } from 'react';
import { PickProperties } from 'ts-essentials';

type Brand = {
  id: string;
  slug: string;
  name: string;
  favicon: string;
  logo: string;
  cookiePolicyURL: string;
  supportEmail: string;
};

interface GlobalState {
  hasAppliedParamsFromUrlRef: React.MutableRefObject<boolean>;
  tpName: string;
  brand: Brand;
}

const initialState: GlobalState = {
  hasAppliedParamsFromUrlRef: { current: false },
  tpName: '',
  brand: {
    id: '',
    slug: '',
    name: '',
    favicon: '',
    logo: '',
    cookiePolicyURL: '',
    supportEmail: '',
  },
};

const GlobalStateContext = React.createContext<
  [GlobalState, React.Dispatch<React.SetStateAction<GlobalState>>]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>([initialState, () => {}]);

export const useGlobalState = <TKey extends keyof GlobalState>(key: TKey) => {
  const [state, setState] = useContext(GlobalStateContext);

  const property = state[key];

  const setStateProperty = (value: GlobalState[TKey]) => {
    setState((state) => {
      if (state[key] === value) return state;
      return { ...state, [key]: value };
    });
  };

  return [property, setStateProperty] as const;
};

type GlobalRefs = PickProperties<GlobalState, React.MutableRefObject<unknown>>;

export const useGlobalRef = <TKey extends keyof GlobalRefs>(key: TKey) => {
  const [state] = useContext(GlobalStateContext);
  const ref = state[key];

  return ref;
};

export const GlobalStateProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<GlobalState>(initialState);

  return (
    <GlobalStateContext.Provider value={[state, setState]}>
      {children}
    </GlobalStateContext.Provider>
  );
};
