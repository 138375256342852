import * as React from 'react';

import { Spinner } from './spinner';

export const SpinnerSection: React.FC = () => {
  return (
    <section className="spinner-section">
      <Spinner />
    </section>
  );
};
