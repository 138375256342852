import * as React from 'react';

type AsyncComponentProps = {
  asyncState: {
    isLoading: boolean;
    error: Error | undefined;
    hasResult: boolean;
  };
  renderLoading: React.ReactNode;
  renderError: (error: Error) => React.ReactNode;
};

export const AsyncComponent: React.FC<AsyncComponentProps> = ({
  asyncState,
  renderError,
  renderLoading,
  children,
}) => {
  const { isLoading, error, hasResult } = asyncState;
  if (isLoading) {
    return <>{renderLoading}</>;
  }
  if (error) {
    return <>{renderError(error)}</>;
  }
  if (!hasResult) {
    return <>{renderLoading}</>;
  }

  return <>{children}</>;
};
